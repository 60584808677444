<template>
    <Page behind-nav>
        <section role="presentation">
            <div aria-hidden="true" class="home-hero">
                <div class="home-hero__background">
                    <img ref="hero-fallback" class="rmnp-hidden" :src="$asset('videos/home/hero_16_9.jpg')" />
                    <DynamicVideoSet
                        v-autoplay="{ ref: 'hero-fallback' }"
                        disableremoteplayback
                        x-webkit-airplay="deny"
                        class="rm-hidden"
                        loop
                        playsinline
                        muted
                        aria-hidden="true"
                        contenteditable="false"
                        :poster="$asset('videos/home/hero_16_9.jpg')"
                        :sources="[
                            { src: $asset('videos/home/hero_16_9.mp4'), media: '(min-width: 1100px)' },
                            { src: $asset('videos/home/hero_1_1.mp4'), media: '(min-width: 600px)' },
                            { src: $asset('videos/home/hero_4_5.mp4') },
                        ]"
                    />
                </div>
                <div class="container">
                    <div class="home-hero__content"></div>
                </div>
            </div>
            <div class="home-hero-copy dynamic-container">
                <div class="procreate-copy procreate-copy--center procreate-copy--bright">
                    <h1 class="sr-only">{{ $t("home.title") }}</h1>
                    <p class="text-subheading">{{ $t("home.hero.leader") }}</p>
                    <h2 class="text-heading text-heading--lg">{{ $t("home.hero.heading") }}</h2>
                    <p class="text-body" v-html="$t('home.hero.body')"></p>
                </div>
            </div>
        </section>

        <!-- PRODUCT -->
        <section role="presentation" class="dynamic-container home-first-section home-section">
            <h2 class="sr-only">{{ $t("home.products.heading") }}</h2>
            <div class="home-grid">
                <!-- Procreate -->
                <div class="home-product-tile home-product-tile--half">
                    <div class="home-product-tile__background"></div>
                    <div class="home-product-tile__copy home-product-tile__copy--center">
                        <ProLink :to="{ name: 'procreate' }" aria-hidden="true" tabindex="-1" class="home-product-tile__click-catch">&nbsp;</ProLink>
                        <ProcreateWordmark class="home-product-tile__wordmark" style="color: white" aria-hidden="true" />
                        <h3 class="sr-only">{{ $t("home.procreate.product_title") }}</h3>
                        <p class="text-heading" v-html="$t('home.procreate.heading')"></p>
                        <div class="home-product-tile__buttons">
                            <ProButton
                                pill
                                :to="{ name: 'procreate' }"
                                :aria-label="$t('home.procreate.prompt_label', { learn_more: $t('global.learn_more') })"
                                >{{ $t("global.learn_more") }}</ProButton
                            >
                            <ProButton
                                pill
                                variant="primary"
                                target="_blank"
                                v-fathom-event:click="['TYVOSEIS']"
                                :aria-label="`${$t('global.buy')} ${$t('home.procreate.product_title')}`"
                                href="https://apps.apple.com/app/apple-store/id425073498?pt=345446&ct=procreate.com&mt=8"
                                >{{ $t("global.buy_now") }}</ProButton
                            >
                        </div>
                        <p
                            class="text-body text-body--sm"
                            v-html="
                                procreatePrice ? $t('global.product_price', { price: procreatePrice.formattedPrice + ' ' + procreatePrice.currency }) : '&nbsp;'
                            "
                        ></p>
                    </div>
                    <div class="home-product-tile__media home-product-tile__media--media-bottom home-product-tile__media--procreate">
                        <img :src="$asset($t('home.procreate.asset'))" width="1930" height="1170" />
                    </div>
                </div>

                <!-- Dreams -->
                <div class="home-product-tile home-product-tile--half home-product-tile--background-left">
                    <div class="home-product-tile__background"></div>
                    <div class="home-product-tile__copy home-product-tile__copy--center">
                        <ProLink :to="{ name: 'dreams' }" aria-hidden="true" tabindex="-1" class="home-product-tile__click-catch">&nbsp;</ProLink>
                        <DreamsWordmark class="home-product-tile__wordmark" style="color: white" aria-hidden="true" />
                        <h3 class="sr-only">{{ $t("home.dreams.product_title") }}</h3>
                        <p class="text-heading" v-html="$t('home.dreams.heading')"></p>
                        <div class="home-product-tile__buttons">
                            <ProButton pill :to="{ name: 'dreams' }" :aria-label="$t('home.dreams.prompt_label', { learn_more: $t('global.learn_more') })">{{
                                $t("global.learn_more")
                            }}</ProButton>
                            <ProButton
                                pill
                                variant="primary"
                                target="_blank"
                                v-fathom-event:click="['T2LCHAZD']"
                                :aria-label="`${$t('global.buy')} ${$t('home.dreams.product_title')}`"
                                href="https://apps.apple.com/app/apple-store/id1595520602?pt=345446&ct=procreate.com&mt=8"
                                >{{ $t("global.buy_now") }}</ProButton
                            >
                        </div>
                        <p
                            class="text-body text-body--sm"
                            v-html="dreamsPrice ? $t('global.product_price', { price: dreamsPrice.formattedPrice + ' ' + dreamsPrice.currency }) : '&nbsp;'"
                        ></p>
                    </div>
                    <div class="home-product-tile__media home-product-tile__media--dreams">
                        <img :src="$asset($t('home.dreams.asset'))" width="1930" height="1130" />
                    </div>
                </div>

                <!-- Pocket -->
                <div class="home-product-tile home-product-tile--1-4">
                    <div class="home-product-tile__background"></div>
                    <div class="home-product-tile__copy">
                        <ProLink :to="{ name: 'pocket' }" aria-hidden="true" tabindex="-1" class="home-product-tile__click-catch">&nbsp;</ProLink>
                        <div>
                            <PocketWordmark class="home-product-tile__wordmark" style="color: white" aria-hidden="true" />
                        </div>
                        <h3 class="sr-only">{{ $t("home.pocket.product_title") }}</h3>
                        <p class="text-heading" v-html="$t('home.pocket.heading')"></p>
                        <div class="home-product-tile__buttons">
                            <ProButton pill :to="{ name: 'pocket' }" :aria-label="$t('home.pocket.prompt_label', { learn_more: $t('global.learn_more') })">{{
                                $t("global.learn_more")
                            }}</ProButton>
                            <ProButton
                                pill
                                variant="primary"
                                target="_blank"
                                v-fathom-event:click="['PCZGEBYS']"
                                :aria-label="`${$t('global.buy')} ${$t('home.pocket.product_title')}`"
                                href="https://apps.apple.com/app/apple-store/id916366645?pt=345446&ct=procreate.com&mt=8"
                                >{{ $t("global.buy_now") }}</ProButton
                            >
                        </div>
                        <p
                            class="text-body text-body--sm"
                            v-html="pocketPrice ? $t('global.product_price', { price: pocketPrice.formattedPrice + ' ' + pocketPrice.currency }) : '&nbsp;'"
                        ></p>
                    </div>
                    <div class="home-product-tile__media home-product-tile__media--pocket">
                        <img :src="$asset($t('home.pocket.asset'))" width="758" height="930" />
                    </div>
                </div>

                <!-- Human Creativity -->
                <div class="home-product-tile home-product-tile--3-4 home-product-tile--background-true-center home-product-tile--whats-new">
                    <div class="home-product-tile__background">
                        <picture>
                            <source media="(min-width: 1080px)" :srcset="$asset('images/home/human-creativity-lg.jpg')" />
                            <!-- <source media="(min-width: 823px)" :srcset="$asset('images/home/human-creativity-md.jpg')" /> -->
                            <img :src="$asset('images/home/human-creativity-md.jpg')" alt="" />
                        </picture>
                        <div class="gradient"></div>
                    </div>
                    <div class="home-product-tile__copy home-product-tile__copy--left" style="justify-content: space-between">
                        <ProLink :to="{ name: 'ai' }" aria-hidden="true" tabindex="-1" class="home-product-tile__click-catch">&nbsp;</ProLink>
                        <h3 class="text-subheading text-subheading--sm"><ProIcon icon="generate" />{{ $t("ai.homepage-tile_subtitle") }}</h3>
                        <div class="whats-new-copy">
                            <div>
                                <p class="text-heading" v-html="$t('ai.homepage-tile_title')"></p>
                                <p class="text-body" v-html="$t('ai.homepage-tile_body')"></p>
                            </div>
                            <div class="home-product-tile__buttons">
                                <ProButton variant="primary" :to="{ name: 'ai' }">{{ $t("global.learn_more") }}<ProIcon icon="cta-right" /></ProButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- LEARNING -->
        <section role="presentation" class="dynamic-container home-section home-section--learning">
            <div class="procreate-copy home-copy">
                <h2 class="text-subheading">{{ $t("home.learning.leader") }}</h2>
                <p class="text-heading text-heading--md" v-html="$t('home.learning.heading')"></p>
            </div>
            <div class="home-grid">
                <!-- Help Center -->
                <ProLink
                    class="home-learning-tile"
                    href="https://help.procreate.com"
                    target="_blank"
                    :aria-label="`${$t('home.help_center.heading')} – ${$t('home.help_center.body')}`"
                >
                    <div class="home-learning-tile__image" aria-hidden="true">
                        <picture>
                            <source media="(min-width: 1080px)" :srcset="$asset($t('home.help_center.big_asset'))" />
                            <img style="object-position: 50% 100%" :src="$asset($t('home.help_center.asset'))" alt="" />
                        </picture>
                        <div class="home-learning-tile__prompt">
                            <ProIcon icon="arrow-ne" />
                        </div>
                    </div>
                    <div class="home-learning-tile__copy" aria-hidden="true">
                        <h3 class="text-heading text-heading--sm" v-html="$t('home.help_center.heading')"></h3>
                        <p class="text-body text-body--sm" v-html="$t('home.help_center.body')"></p>
                    </div>
                </ProLink>

                <!-- B Series -->
                <ProLink
                    class="home-learning-tile home-learning-tile--half"
                    :to="{ name: 'beginners-series' }"
                    :aria-label="`${$t('home.b_series.heading')} – ${$t('home.b_series.body')}`"
                >
                    <div class="home-learning-tile__image" aria-hidden="true">
                        <img style="object-position: 0%" :src="$asset('images/home/b-series.jpg')" alt="" />
                        <div class="home-learning-tile__prompt">
                            <ProIcon style="transform-origin: center center; transform: scale(0.75) translate(6%)" icon="play" />
                        </div>
                    </div>
                    <div class="home-learning-tile__copy" aria-hidden="true">
                        <h3 class="text-heading text-heading--sm" v-html="$t('home.b_series.heading')"></h3>
                        <p class="text-body text-body--sm" v-html="$t('home.b_series.body')"></p>
                    </div>
                </ProLink>

                <!-- Education -->
                <ProLink
                    class="home-learning-tile home-learning-tile--half"
                    href="https://education.procreate.com"
                    target="_blank"
                    :aria-label="`${$t('home.education.heading')} – ${$t('home.education.body')}`"
                >
                    <div class="home-learning-tile__image" aria-hidden="true">
                        <picture>
                            <source media="(min-width: 1080px)" :srcset="$asset('images/home/education-lg.jpg')" />
                            <img style="object-position: 30%" :src="$asset('images/home/education.jpg')" alt="" />
                        </picture>
                        <div class="home-learning-tile__prompt home-learning-tile__prompt--light">
                            <ProIcon icon="arrow-ne" />
                        </div>
                    </div>
                    <div class="home-learning-tile__copy" aria-hidden="true">
                        <h3 class="text-heading text-heading--sm" v-html="$t('home.education.heading')"></h3>
                        <p class="text-body text-body--sm" v-html="$t('home.education.body')"></p>
                    </div>
                </ProLink>
            </div>
        </section>

        <!-- CAREERS -->
        <section role="presentation" class="home-section">
            <div class="home-careers-tile">
                <div class="home-careers-tile__bg">
                    <picture>
                        <source media="(min-width: 1080px)" :srcset="$asset('images/home/careers-lg.jpg')" />
                        <img :src="$asset('images/home/careers.jpg')" alt="" />
                    </picture>
                </div>
                <div class="home-careers-tile__copy home-copy">
                    <ProLink :to="{ name: 'careers' }" class="home-careers-tile__click-catch" aria-hidden="true">&nbsp;</ProLink>
                    <h2 class="a11y-heading text-heading text-heading--md">{{ $t("home.careers.a11y_heading") }}</h2>
                    <div aria-hidden="true">
                        <h2 class="text-heading text-heading--md">
                            <span v-if="positions" v-html="$t('home.careers.heading')"></span>
                            <span v-else v-html="$t('home.careers.a11y_heading')"></span>
                        </h2>
                        <!-- Careers Marquee -->
                        <div v-if="positions" class="careers-marquee text-heading text-heading--md" aria-hidden="true">
                            <ul role="presentation">
                                <li v-if="positions" v-for="position in positions.filter((pos) => pos.slug != 'expressions-of-interest')" :key="position.id">
                                    <ProLink :to="{ name: 'careers-position', params: { position: position.slug } }"
                                        >{{ position.title }}<ProIcon icon="cta-right"
                                    /></ProLink>
                                </li>
                            </ul>
                            <ul role="presentation">
                                <li v-for="position in positions.filter((pos) => pos.slug != 'expressions-of-interest')" :key="position.id">
                                    <ProLink :to="{ name: 'careers-position', params: { position: position.slug } }"
                                        >{{ position.title }}<ProIcon icon="cta-right"
                                    /></ProLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <p class="text-body" v-html="$t('home.careers.body')"></p>
                    <div class="procreate-copy__buttons">
                        <ProButton pill variant="primary" :to="{ name: 'careers' }" :aria-label="$t('home.careers.prompt_label')">{{
                            $t("global.learn_more")
                        }}</ProButton>
                    </div>
                </div>
            </div>
        </section>

        <!-- INSIGHT -->
        <section role="presentation" class="dynamic-container home-section">
            <!-- If insight articles, show articles + prompt text -->
            <div v-if="articles" class="home-grid">
                <div class="home-insight-tile">
                    <div class="procreate-copy home-copy">
                        <h2 class="text-subheading">{{ $t("home.insight.leader") }}</h2>
                        <p class="text-heading" v-html="$t('home.insight.heading')"></p>
                        <p class="text-body" v-html="$t('home.insight.body')"></p>
                        <div class="procreate-copy__buttons">
                            <ProButton pill :to="{ name: 'insight' }" :aria-label="$t('home.insight.prompt_label')">{{ $t("global.read_more") }}</ProButton>
                        </div>
                    </div>
                </div>
                <div v-if="articles" v-for="article in articles" :key="article.id" class="home-insight-tile home-insight-tile--half">
                    <InsightLinkTile :article="article" />
                </div>
            </div>

            <!-- If no insight articles, just show prompt text -->
            <div v-else>
                <div class="procreate-copy home-copy home-insight-tile">
                    <h2 class="text-subheading">{{ $t("home.insight.leader") }}</h2>
                    <p class="text-heading" v-html="$t('home.insight.heading')"></p>
                    <p class="text-body" v-html="$t('home.insight.body')"></p>
                    <div class="procreate-copy__buttons">
                        <ProButton pill :to="{ name: 'insight' }" :aria-label="$t('home.insight.prompt_label')">{{ $t("global.learn_more") }}</ProButton>
                    </div>
                </div>
            </div>
        </section>
    </Page>
</template>

<script>
import { mapGetters } from "vuex";

import ProcreateWordmark from "@/components/wordmarks/ProcreateWordmark";
import PocketWordmark from "@/components/wordmarks/PocketWordmark";
import DreamsWordmark from "@/components/wordmarks/DreamsWordmark";
import InsightLinkTile from "@/components/insight/InsightLinkTile";
import DynamicVideoSet from "@/components/DynamicVideoSet.vue";
import { organization, brand, procreateProduct, pocketProduct, createJsonLdWrapper } from "@/utils/schema.org.js";

export default {
    components: {
        ProcreateWordmark,
        PocketWordmark,
        DreamsWordmark,
        InsightLinkTile,
        DynamicVideoSet,
    },

    computed: {
        ...mapGetters({
            procreatePrice: "appStore/getProcreate",
            pocketPrice: "appStore/getPocket",
            dreamsPrice: "appStore/getDreams",
            positions: "employment/getHomePositions",
            articles: "insight/getHomeArticles",
        }),
    },
    methods: {
        async fetchData() {
            try {
                await Promise.all([
                    this.$store.dispatch("appStore/fetchPrices"),
                    this.$store.dispatch("insight/fetchHomeArticles"),
                    this.$store.dispatch("employment/fetchHomePositions"),
                ]);
            } catch (e) {
                console.error(e);
            }
        },
    },

    mounted() {
        this.fetchData();
    },

    watch: {
        $route() {
            this.$store.dispatch("insight/fetchHomeArticles");
        },
    },

    head() {
        const i18nSeo = this.$nuxtI18nSeo();
        return {
            script: [
                createJsonLdWrapper({
                    ...organization,
                    brand,
                    owns: [procreateProduct, pocketProduct],
                }),
            ],
            meta: [...i18nSeo.meta],
            link: [...i18nSeo.link],
            bodyAttrs: {
                class: "ultra-dark-mode home include-dynamic-container-nav",
            },
        };
    },
};
</script>
