<template>
    <ProLink
        class="insight-link-tile"
        :class="layout ? `insight-link-tile--${layout}` : ''"
        v-bind="$attrs"
        :aria-label="
            $t('insight.tile.label', {
                title: article.title,
                category: article.category.title,
                date: formatDateForLocale(article.customDisplayDate, article.language),
            })
        "
        :to="{ name: 'insight-year-slug', params: { year: article.year, slug: article.slug } }"
        :title="`${article.category.title} - ${article.title}`"
    >
        <div class="insight-link-tile__image-wrapper">
            <canvas ref="blurCanvas" class="insight-link-tile__image" width="200" height="200"></canvas>
            <div
                class="insight-link-tile__image"
                aria-hidden="true"
                :style="{
                    'background-image': `url('${article.image}')`,
                }"
            ></div>
        </div>
        <div class="insight-link-tile__copy" aria-hidden="true">
            <div class="insight-link-tile__meta">
                <span :style="{ color: article.category.color }">{{ article.category.title }}</span>

                <span class="default-display">{{ formatDateForLocale(article.customDisplayDate, article.language) }}</span>
                <span class="compact-display">{{ formatDateForLocale(article.customDisplayDate, article.language, "short") }}</span>
            </div>
            <h3 class="insight-link-tile__title text-heading text-heading--xs">{{ article.title }}</h3>
        </div>
    </ProLink>
</template>

<script>
import { createImgBlur, setImgBlur } from "@/utils/sanity";
import { formatDateForLocale } from "~/utils";

export default {
    props: {
        article: Object,
        layout: {
            type: String,
            default: "",
            validator(value) {
                return ["", "featured", "compact"].includes(value);
            },
        },
    },

    methods: {
        formatDateForLocale,
    },

    mounted() {
        setImgBlur(this.$refs.blurCanvas, this.blurData);
    },

    beforeMount() {
        this.blurData = createImgBlur(this.article.blur);
    },
};
</script>
